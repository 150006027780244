import React from "react";
import { useTasksDataContext } from "@/store/TasksProvider";
import cn from "classnames";
import cls from "./styles.module.scss";

export const TaskFilter = () => {
  const { showPartners, setShowPartners } = useTasksDataContext();
  return (
    <div className={cls.wrapper}>
      <button
        type="button"
        onClick={() => setShowPartners(false)}
        className={cn(cls.btn, { [cls.active]: !showPartners })}
      >
        Our
      </button>
      <button
        type="button"
        onClick={() => setShowPartners(true)}
        className={cn(cls.btn, { [cls.active]: showPartners })}
      >
        Partners
      </button>
    </div>
  );
};
