import React from "react";
import { TaskI } from "@/api/tasks";
import cls from "@/styles/components/TasksList.module.scss";
import LogoSvg from "@/assets/images/logo.svg";
import { TaskButton } from "@/components/tasks/TaskButtons/TaskButtons";

export const TaskItem: React.FC<{ task: TaskI }> = ({ task }) => {
  return (
    <div className={cls.item}>
      <div className={cls.image}>
        {task.image ? (
          <img src={task.image} alt={task.name} />
        ) : (
          <LogoSvg width="100%" height="100%" />
        )}
      </div>
      <div className={cls.info}>
        <div className={cls.name}>{task.name}</div>
        <div className={cls.token}>{task.token_count} $GEN</div>
      </div>
      <TaskButton task={task} />
    </div>
  );
};
