// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MVbQP27Q{display:flex;flex-direction:row;gap:4px;padding:4px;background:#151515;border-radius:8px;border-left:1px solid #202020;border-bottom:1px solid #202020;border-right:1px solid #202020}.CKDRujrw{flex:1;font-size:1rem;line-height:1.375rem;padding:10px;color:#ababab}.CKDRujrw.r77TULk1{color:#00ff02;background:#004b01;border-radius:8px;border-left:1px solid rgba(0,255,2,.1019607843);border-bottom:1px solid rgba(0,255,2,.1019607843);border-right:1px solid rgba(0,255,2,.1019607843)}`, "",{"version":3,"sources":["webpack://./src/components/tasks/TaskFilter/styles.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CAEA,kBAAA,CACA,iBAAA,CACA,6BAAA,CACA,+BAAA,CACA,8BAAA,CAGF,UACE,MAAA,CACA,cAAA,CACA,oBAAA,CACA,YAAA,CACA,aAAA,CAGF,mBACE,aAAA,CACA,kBAAA,CAEA,iBAAA,CACA,+CAAA,CACA,iDAAA,CACA,gDAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 4px;\n  padding: 4px;\n\n  background: #151515;\n  border-radius: 8px;\n  border-left: 1px solid #202020;\n  border-bottom: 1px solid #202020;\n  border-right: 1px solid #202020;\n}\n\n.btn {\n  flex: 1;\n  font-size: 1rem;\n  line-height: 1.375rem;\n  padding: 10px;\n  color: #ABABAB;\n}\n\n.btn.active {\n  color: #00FF02;\n  background: #004B01;\n\n  border-radius: 8px;\n  border-left: 1px solid #00FF021A;\n  border-bottom: 1px solid #00FF021A;\n  border-right: 1px solid #00FF021A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MVbQP27Q`,
	"btn": `CKDRujrw`,
	"active": `r77TULk1`
};
export default ___CSS_LOADER_EXPORT___;
