import $api from "@/api/index";
import { useCallback, useEffect, useState } from "react";

export type TaskStatus =
  | "not_started"
  | "started"
  | "waiting_transaction"
  | "completed";

export type TaskI = {
  id: number;
  name: string;
  token_count: number;
  link: string;
  image: string;
  friends_count: number | null;
  status: TaskStatus;
};

const statusOrder: Record<TaskStatus, number> = {
  waiting_transaction: 1,
  not_started: 2,
  started: 3,
  completed: 4,
};

export async function getTasks(): Promise<TaskI[]> {
  const res = await $api.get(`/tasks/`);

  return res.data;
}

export function useTasks(): [TaskI[], boolean, () => Promise<void>] {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<TaskI[]>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getTasks();

      setData(r.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]));
    } catch (error) {
      console.log(error);
      // showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading, load];
}

export async function startTaskApi(id: number): Promise<TaskI> {
  const res = await $api.get(`/tasks/${id}/start_task`);

  return res.data;
}

export async function checkTaskApi(id: number): Promise<TaskI> {
  const res = await $api.get(`/tasks/${id}/check_task`);

  return res.data;
}

export async function receiveRewardApi(id: number): Promise<TaskI> {
  const res = await $api.get(`/tasks/${id}/receive_reward`);

  return res.data;
}
