import cls from "@/styles/components/TasksList.module.scss";
import React, { useEffect, useState } from "react";
import { useTasksDataContext } from "@/store/TasksProvider";
import { Loader } from "@/components/Loader/Loader";
import { removePreloader } from "@/utils/helpers";
import { TaskItem } from "@/components/tasks/TaskItem/TaskItem";
import { TaskFilter } from "@/components/tasks/TaskFilter/TaskFilter";

export const TasksList = () => {
  const { tasks, tasksLoading } = useTasksDataContext();

  const [preloaderRemoved, setPreloaderRemoved] = useState(false);

  useEffect(() => {
    if (!tasksLoading && !preloaderRemoved) {
      removePreloader();
      setPreloaderRemoved(true);
    }
  }, [tasksLoading, preloaderRemoved]);

  if (tasksLoading)
    return (
      <div className={cls.wrapper}>
        <div className={cls.title}>Tasks</div>
        <Loader />
      </div>
    );

  return (
    <div className={cls.wrapper}>
      <div className={cls.title}>Tasks</div>
      <TaskFilter />
      <div className={cls.list}>
        {tasks.map((t) => (
          <TaskItem task={t} key={t.id} />
        ))}
        {tasks.length === 0 && (
          <div className={cls.empty}>No tasks from partners right now</div>
        )}
      </div>
    </div>
  );
};
